// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.
import axios from 'axios'

const BASE_API_URL = '/api/v1'

export class BaseService {
  id
  static collection = null
  static constructUrl(path) {
    let parts = [BASE_API_URL]
    if (this.collection) parts.push(this.collection)
    if (path) {
      if (path instanceof Array) parts = parts.concat(path)
      else parts.push(path.toString())
    }
    //console.log('static', path, parts)
    const url = parts.filter(x => !!x).join('/')
    //console.log('static', path, parts, url)
    return url
  }
  static constructConfig(props = {}) {
    const {forSelect, params = {} } = props
    const config = forSelect ? {params: {...params, for: 'select'}} : {params}
    return config
  }
  constructUrl(path) {
    let parts = [this.id]
    if (path) {
      if (path instanceof Array) parts = parts.concat(path)
      else parts.push(path.toString())
    }
    const url = this.constructor.constructUrl(parts.filter(x => !!x))
    //console.log('instance', path, parts, url)
    return url
  }

  constructor(id) {
    //console.log(this.constructor.collection, id)
    this.id = id
  }

  get() {
    return axios.get(this.constructUrl(), {})
  }

  update(params) {
    return axios.patch(this.constructUrl(), params)
  }

  history(params) {
    return axios.get(this.constructUrl('history'), {params})
  }

  static list(props) {
    const config = this.constructConfig(props)
    return axios.get(this.constructUrl(), config)
  }
  static create(params) {
    return axios.post(this.constructUrl(), params)
  }

  async uploadFile(props) {
    //console.log(props)
    const {method, file, onStart, onProgress, onFinish} = props
    try {
      if (onStart) onStart()
  
      return await axios.patch(this.constructUrl(method), file, {
        onUploadProgress: onProgress,
        headers: {
          'Content-Type': file.type
        }
      })
    } finally {
      if (onFinish) onFinish()
    }
  }

  async downloadFile(props) {
    const {method, filename, onStart, onProgress, onFinish} = props
    try {
      if (onStart) onStart()

      const response = await axios.get(this.constructUrl(method), {
        responseType: "blob",
        onDownloadProgress: onProgress
      })
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"]
        })
      )

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
    } finally {
      if (onFinish) onFinish()
    }
  }

  async downloadAudio(props) {
    const {method, filename, onStart, onProgress, onFinish} = props
    try {
      if (onStart) onStart()

      const response = await axios.get(this.constructUrl(method), {
        responseType: "blob",
        onDownloadProgress: onProgress
      })
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"]
        })
      )

      const audio = document.createElement("audio")
      audio.src = url
      document.body.appendChild(audio)
      audio.play()
    } finally {
      if (onFinish) onFinish()
    }
  }
 
}
